// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-medical-disclaimer-js": () => import("./../../../src/pages/medical-disclaimer.js" /* webpackChunkName: "component---src-pages-medical-disclaimer-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/services-template.js" /* webpackChunkName: "component---src-templates-services-template-js" */),
  "component---src-templates-staff-template-js": () => import("./../../../src/templates/staff-template.js" /* webpackChunkName: "component---src-templates-staff-template-js" */)
}

