const themeStyles = {
  fonts: {},
  fontSize: {
    h1: "3rem",
    h2: "2.8rem",
    h3: "2.369rem",
    h4: "1.777rem",
    h5: "1.333rem",
    h6: "1.1rem",
    p: "16px",
    small: "0.75rem",
  },
  colors: {
    primary: "#09425a",
    secondary: "#5f6c7b",
    accent: "#ef4565",
    dark: "#222222",
    lightGray: "#e0e0e0",
    light: "#F0F0F0",
  },
  boxShadow: {
    primary: "0px 2px 4px 0px rgba(0, 0, 0, 0.1);",
  },
}

export default themeStyles
