import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import themeStyles from "./src/themes/theme"

const GlobalStyles = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 112.5%/1.45em "Montserrat", sans-serif, sans-serif;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}
h1 {
  font-size: ${props => props.theme.fontSize.h1};
  color: ${props => props.theme.colors.primary};
}

h2 {
  font-size: ${props => props.theme.fontSize.h2};
}

h3 {
  font-size: ${props => props.theme.fontSize.h3};
}

h4 {
  font-size: ${props => props.theme.fontSize.h4};
}

h5 {
  font-size: ${props => props.theme.fontSize.h5};
}
h6 {
  font-size: ${props => props.theme.fontSize.h6};
}

p {
  font-size: ${props => props.theme.fontSize.p};
  line-height: 1.6;
  margin: 0 0 1rem;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

small,
.text_small {
  font-size: ${props => props.theme.fontSize.small};
}

button, .btn {
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  border-radius: 3px;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  border: none;
  box-shadow: none;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
}

button:hover, .btn:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #094067;
  transform: scale(1.01);
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
  h1 {
    font-size: 3.5rem;
  }
  p {
  text-align: justify;
  }
}

@media (min-width: 768px) {
    width: 50vw;
    p {
  text-align: left;
}
  }
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={themeStyles}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
)
